<template>
  <v-container>
    <v-card rounded="lg">
      <v-card-title>
        {{ $t("navigation.preferences") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="text-h6">{{ $t("preferences.language") }}</div>
        <v-radio-group :value="$i18n.locale" @change="changeLocale">
          <v-radio v-for="item in languages" :key="item.locale" :value="item.locale">
            <template v-slot:label>
              <flag :iso="item.iso" />
              <span class="ml-2">{{ item.locale.toUpperCase() }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Preferences",
  data() {
    return {
      languages: [
        { iso: "IT", locale: "it" },
        { iso: "GB", locale: "en" },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      localStorage.setItem("locale", locale);
      this.$router.push({ name: this.$route.name, params: { lang: locale } });
    },
  },
};
</script>
